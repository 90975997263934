<template>
  <div>
    <div>
      <Header
        :title="$t('assignments.assignmentsEdit').toString()"
        :subtitle="subtitle"
        :show-back-button="true"
        :items="headerButtons"
        @back="$router.push({ name: 'projectMappingsAssignments' })"
        @save="updateAssignment"
        @showVersions="onShowVersions"
      />
      <div class="card card-custom">
        <div class="card-body">
          <FormHelper
            ref="form"
            v-model="assignments"
            :form="form"
            :config="config"
          />
        </div>
      </div>
    </div>

    <BackupModal
      v-if="assignments"
      v-model="showVersionsModal"
      :object="assignments"
      classname="Assignment"
    />
  </div>
</template>

<script>
import Assignments from "@/components/Assignments/assignments";
import { mapGetters } from "vuex";
import Header from "@/components/Tools/Header/Header.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { formatDate } from "@/components/Tools/helperFunctions";
import BackupModal from "@/components/Backup/BackupModal.vue";

export default {
  name: "ProjectMappingsAssignmentsEditor",
  components: { BackupModal, FormHelper, Header },
  data() {
    return {
      subtitle: "",
      headerButtons: [
        {
          type: "button",
          icon: this.$store.getters["config/icons"].version,
          tooltip: this.$t("general.showVersions"),
          class: "btn-outline-primary",
          emit: "showVersions",
          disabledWhenLoading: true
        },
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save",
          disabledWhenLoading: true
        }
      ],
      config: {
        snippetPrefix: "assignments",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("assignments.assignmentsName"),
          name: "name",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("assignments.description"),
          name: "description",
          type: "textarea",
          default: "",
          validations: {
            required: false
          }
        },
        {
          label: this.$t("assignments.projects"),
          name: "projects",
          type: "multiselect",
          clearable: true,
          options: [],
          validations: {
            required: true
          }
        },
        {
          label: this.$t("assignments.values"),
          type: "json",
          name: "values",
          returnJson: false,
          fields: [
            {
              name: "key",
              label: this.$t("assignments.key")
            },
            {
              name: "value",
              label: this.$t("assignments.value")
            }
          ],
          validations: {
            required: true
          }
        }
      ],
      assignments: {},
      assignmentId: false,
      showVersionsModal: false
    };
  },
  computed: {
    ...mapGetters(["projects"])
  },
  watch: {
    projects: function () {
      this.setProjects();
    }
  },
  mounted() {
    this.assignmentId = this.$route.params.id;
    this.loadAssignment();
    this.setProjects();
  },
  methods: {
    setProjects() {
      // Fills multiselect projects
      const formProjects = Object.values(this.form).find(
        element => element.name === "projects"
      );
      formProjects.options = this.getProjectOptions();
    },
    loadAssignment() {
      addEventToLoadingQueue({
        key: "loadAssignment"
      });
      Assignments.get(this.assignmentId)
        .then(response => {
          let subtitleParts = [];
          if (response.data.updated_by_user?.full_name) {
            subtitleParts.push(response.data.updated_by_user?.full_name);
          }
          subtitleParts.push(formatDate(response.data.updated_at));

          this.subtitle = subtitleParts.join(", ");

          this.assignments = response.data;
          this.assignments.projects = response.data.projects.map(
            project => project.id
          );

          removeEventFromLoadingQueue({
            key: "loadAssignment"
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    getProjectOptions() {
      return this.projects.map(project => ({
        label: project.name,
        value: project.id
      }));
    },
    updateAssignment() {
      addEventToLoadingQueue({
        key: "updateAssignment"
      });
      Assignments.update(this.assignmentId, this.assignments)
        .then(() => {
          removeEventFromLoadingQueue({
            key: "updateAssignment",
            type: "success",
            prefix: "config",
            name: "configUpdated"
          });
          this.$router.push({ name: "projectMappingsAssignments" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    onShowVersions() {
      this.showVersionsModal = true;
    }
  }
};
</script>
