<template>
  <div>
    <Header
      :title="$t('assignments.assignmentsCreate').toString()"
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectMappingsAssignments' })"
      @save="saveAssignments"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="assignments"
          :form="form"
          :config="config"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Assignments from "@/components/Assignments/assignments";
import { mapGetters } from "vuex";
import Header from "@/components/Tools/Header/Header.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";

export default {
  components: { FormHelper, Header },
  props: {},
  data() {
    return {
      headerButtons: [
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save"
        }
      ],
      config: {
        snippetPrefix: "assignments",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("assignments.assignmentsName"),
          name: "name",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("assignments.description"),
          name: "description",
          type: "textarea",
          default: "",
          validations: {
            required: false
          }
        },
        {
          label: this.$t("assignments.projects"),
          name: "projects",
          type: "multiselect",
          clearable: true,
          options: [],
          validations: {
            required: true
          }
        },
        {
          label: this.$t("assignments.values"),
          type: "json",
          name: "values",
          returnJson: false,
          fields: [
            {
              name: "key",
              label: this.$t("assignments.key")
            },
            {
              name: "value",
              label: this.$t("assignments.value")
            }
          ],
          validations: {
            required: true
          }
        }
      ],
      assignments: {
        name: "",
        projects: [],
        values: [
          {
            key: "",
            value: ""
          }
        ]
      },
      files: []
    };
  },
  computed: {
    ...mapGetters(["projects", "selectedProject"])
  },
  watch: {
    selectedProject: function () {
      this.getProjectOptions();
    }
  },
  mounted() {
    this.getProjectOptions();
    this.assignments.projects = [this.selectedProject.id];
  },
  methods: {
    saveAssignments() {
      addEventToLoadingQueue({
        key: "saveAssignments"
      });
      Assignments.store(this.assignments)
        .then(response => {
          removeEventFromLoadingQueue({
            key: "saveAssignments",
            type: "success",
            prefix: "assignments",
            name: "assignmentsSaved"
          });

          this.$router.push({
            name: "projectMappingsAssignmentsEditor",
            params: { id: response.data.id }
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    backToOverview() {
      this.$router.push({ name: "projectMappingsAssignments" });
    },
    getProjectOptions() {
      // Fills multiselect projects
      const formProjects = Object.values(this.form).find(
        element => element.name === "projects"
      );
      formProjects.options = this.projects?.length
        ? this.projects.map(project => {
          return {
            label: project.name,
            value: project.id
          };
        })
        : [];
    }
  }
};
</script>
