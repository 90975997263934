<template>
  <div>
    <Create v-if="$route.name === 'projectMappingsAssignmentsCreate'" />
    <Editor v-else-if="$route.name === 'projectMappingsAssignmentsEditor'" />
    <Table v-else></Table>
  </div>
</template>

<script>
import Table from "@/components/Assignments/Table.vue";
import Create from "@/components/Assignments/Create.vue";
import Editor from "@/components/Assignments/Editor.vue";

export default {
  components: { Table, Create, Editor }
};
</script>
