<template>
  <div>
    <Header
      :title="$t('assignments.assignments').toString()"
      :items="headerButtons"
      @createAssignments="createAssignments"
    >
      <template #afterItems>
        <file-upload
          ref="upload"
          v-model="files"
          v-b-popover.hover.top="$t('assignments.import')"
          accept="application/json,.yml,.yaml"
          class="btn btn-outline-primary btn-hover ml-2"
          extensions="json,yaml,yml"
          @input-file="importAssignment"
        >
          <i class="fa-light fa-file-import"></i>
        </file-upload>
      </template>
    </Header>
    <TableHelper
      ref="table"
      :items="assignments"
      :fields="fields"
      :actions="actions"
      :enable-filter="true"
      :filterable="filterable"
      :enable-pagination="false"
      default-order-by="name"
      :meta="meta"
      :exportable="true"
      loading-key="loadAssignments"
      @reload-data="loadAssignments"
      @export="exportAssignment"
      @edit="openAssignmentsEditor"
      @delete="deleteAssignments"
      @showVersions="onShowVersions"
      @export-csv="exportCsv"
    />
    <BackupModal
      v-model="showVersionsModal"
      :object="selectedAssignment"
      classname="Assignment"
    />
  </div>
</template>

<script>
import Assignments from "@/components/Assignments/assignments";
import { mapGetters } from "vuex";
import Header from "@/components/Tools/Header/Header.vue";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import BackupModal from "@/components/Backup/BackupModal.vue";
import { useCurrentProjectFilter } from "@/composables/useCurrentProjectFilter";
import FileUpload from "vue-upload-component";

export default {
  components: { FileUpload, BackupModal, TableHelper, Header },
  props: [],
  data() {
    return {
      headerButtons: [
        {
          type: "button",
          title: this.$t("assignments.assignmentsCreate"),
          icon: "fa-light fa-plus",
          emit: "createAssignments"
        }
      ],
      actions: [
        {
          key: "edit",
          icon: this.$store.getters["config/icons"].edit,
          emit: "edit",
          tooltip: this.$t("general.edit")
        },
        {
          key: "export",
          icon: this.$store.getters["config/icons"].exportEntity,
          emit: "export",
          tooltip: this.$t("assignments.export")
        },
        {
          key: "versions",
          icon: this.$store.getters["config/icons"].version,
          emit: "showVersions"
        },
        {
          key: "delete",
          icon: this.$store.getters["config/icons"].delete,
          emit: "delete",
          tooltip: this.$t("general.delete")
        }
      ],
      assignments: [],
      files: [],
      fields: [
        {
          key: "name",
          label: this.$t("table.name"),
          sortable: true,
          thStyle: { width: "200px" }
        },
        {
          key: "description",
          sortable: true,
          label: this.$t("table.description")
        },
        {
          key: "updated_by_user.full_name",
          sortable: true,
          label: this.$t("general.resourceOwner"),
          thStyle: { width: "300px" }
        },
        {
          key: "updated_at",
          formatter: "formatDate",
          label: this.$t("table.updatedAt"),
          sortable: true,
          thStyle: { width: "200px" }
        }
      ],
      // Meta info
      meta: {},
      selectedAssignment: null,
      showVersionsModal: false,
      // Filterable fields
      filterable: {}
    };
  },
  computed: {
    ...mapGetters("route", ["params"]),
    ...mapGetters(["selectedProject", "selectedPresetVersion"]),
    ...mapGetters("instance", ["instance"])
  },
  watch: {
    selectedProject: function () {
      this.loadAssignments();
    },
    selectedPresetVersion: function () {
      this.loadAssignments();
    }
  },
  mounted() {
    this.loadAssignments();
  },
  methods: {
    loadAssignments(payload = {}) {
      this.assignments = [];

      if (this.instance?.isPresetInstance && !this.selectedPresetVersion) {
        return;
      }

      addEventToLoadingQueue({
        key: "loadAssignments"
      });
      let params = { ...this.params(), ...payload };
      const { filter } = useCurrentProjectFilter();

      Assignments.getAll(params, filter.value)
        .then(response => {
          this.assignments = Object.values(response.data);
          this.meta = response.meta;
          this.filterable = response.meta.filterable;
          removeEventFromLoadingQueue({
            key: "loadAssignments"
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    createAssignments() {
      this.$router.push({ name: "projectMappingsAssignmentsCreate" });
    },
    openAssignmentsEditor(assignments) {
      this.$router.push({
        name: "projectMappingsAssignmentsEditor",
        params: { id: assignments.item.id }
      });
    },
    deleteAssignments(assignments) {
      let params = {
        title: this.$t("assignments.deleteAssignmentsTitle", {
          title: assignments.item.name
        }),
        text: this.$t("assignments.deleteAssignmentsText")
      };

      this.$confirmation(params).then(result => {
        if (!result.isConfirmed) {
          return;
        }
        addEventToLoadingQueue({
          key: "deleteAssignment"
        });
        Assignments.delete(assignments.item.id)
          .then(() => {
            removeEventFromLoadingQueue({
              key: "deleteAssignment",
              type: "success",
              prefix: "assignments",
              name: "assignmentsDeleted"
            });

            this.loadAssignments();
          })
          .catch(error => {
            this.$error(error);
          });
      });
    },
    onShowVersions(data) {
      this.selectedAssignment = data.item;
      this.showVersionsModal = true;
    },
    exportAssignment(item) {
      let id = item?.item?.id;
      let name = item?.item?.name;

      addEventToLoadingQueue({ key: "exportAssigmentAction" });
      Assignments.export(id, "json")
        .then(response => {
          const blob = new Blob([JSON.stringify(response)], {
            type: "application/json"
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download =
            "[xentral_connect]" + name.replace(/ /g, "_") + "_" + id;
          link.click();
          URL.revokeObjectURL(link.href);
          removeEventFromLoadingQueue({
            key: "exportAssigmentAction",
            type: "success",
            prefix: "assignments",
            name: "assignmentExportSuccess",
            data: {
              name: name
            }
          });
        })
        .catch(error => {
          this.$error(error);
          removeEventFromLoadingQueue({ key: "exportAssigmentAction" });
        });
    },
    importAssignment(newFile, oldFile) {
      if (newFile && !oldFile) {
        addEventToLoadingQueue({ key: "loadingFile" });

        let importData = new FormData();
        importData.append("file", newFile.file);

        if (this.selectedProject.id) {
          importData.append("project_id", this.selectedProject.id);
        }

        Assignments.import(importData)
          .then(() => {
            this.loadAssignments();
            removeEventFromLoadingQueue({
              key: "loadingFile",
              type: "success",
              prefix: "assignments",
              name: "assignmentImportSuccess"
            });
          })
          .catch(error => {
            this.$error(error);
            removeEventFromLoadingQueue({ key: "loadingFile" });
          });
      }
    },
    exportCsv(params) {
      Assignments.exportCsv(params).catch(error => {
        this.$error(error);
      });
    }
  }
};
</script>
